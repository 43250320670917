<script lang="ts" setup>
  import NavigationBreadcrumb from '~/components/NavigationBreadcrumb/NavigationBreadcrumb.vue'
  import LogoAsCode from '~/components/LogoAsCode/LogoAsCode.vue'
  import { LogoAsCodeFacet } from '~/components/LogoAsCode/LogoAsCodeFacet'
  import useRouteRecord from '~/composables/RouteRecord/RouteRecord'
  import { RouteRecordId } from '~/model/RouteRecord/RouteRecord'

  const { toRoute } = useRouteRecord()
</script>

<template>
  <div class="c-navigation-game">
    <div class="c-navigation-game__aside">
      <NavigationBreadcrumb />
    </div>
    <div class="c-navigation-game__center">
      <LogoAsCode
        :url="toRoute({ name: RouteRecordId.Home })"
        :facets="[LogoAsCodeFacet.InNavigationGame, LogoAsCodeFacet.IsNegative]"
      />
    </div>
    <div class="c-navigation-game__aside">
      <NavigationCinema />
    </div>
  </div>
</template>

<style lang="scss" scoped src="./_NavigationGame.scss" />
